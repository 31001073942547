/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { DataConstant, UrlConstant } from "../../../../../app/constants";
import { SwitchUserType } from "../../../../../app/modules/userType/switchUserType";
import { actionTypes } from "../../../../../redux/filter/filterReducer";
import {
  handleFetchGet,
  handleFetchPost
} from "../../../../../utils/apiCallsProvider";
import { useCommonContext } from "../../../../../app/context/commonContext";
import { checkIsActive } from "../../../../_helpers";
import { useUserType } from "../../../../_helpers/useUserType";
const { REACT_APP_WHITELABELNAME, REACT_APP_HIDEPSF, REACT_APP_PHARMACYURL } = process.env;

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const refSwitchAccount = useRef();
  const dispatch = useDispatch();
  const isDripbar = (REACT_APP_WHITELABELNAME?.toLowerCase() === DataConstant.whiteLabelNames.dripbar.name.toLowerCase());
  const isVSDH = (REACT_APP_WHITELABELNAME?.toLowerCase() === DataConstant.whiteLabelNames.vsdigital.name.toLowerCase());

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  var whiteLableWiseLable = {
    medicalConsent: "aside.menu.medicalConsent"
  }

  //#region Lable as per whitelable
  if (REACT_APP_WHITELABELNAME?.toLowerCase() === DataConstant.whiteLabelNames.dripbar.name.toLowerCase()) {
    whiteLableWiseLable = {
      medicalConsent: "aside.menu.medicalConsentHistory"
    }
  }
  //#endregion

  const [featuresList, setFeaturesList] = useState([]);
  const [whitelabelFeaturesList, setWhitelabelFeaturesList] = useState([]);
  const commonContext = useCommonContext();
  const commonProps = useMemo(() => {
    return {
      queryParams: commonContext.queryParams,
      setQueryParams: commonContext.setQueryParams,
      ids: commonContext.ids,
    };
  }, [commonContext]);

  const resetFilter = () => {
    dispatch({
      type: actionTypes.reset,
    });
    commonProps.setQueryParams({
      ...commonProps.queryParams, searchText: "",
      page: 1, sortField: "", sortOrder: "desc", itemsPerPage: 50
    })
  };
  const {
    userType,
    businessName,
    businessId,
    allowBusinessToAddMembership,
    isWithDoctorNetwork,
  } = useSelector(
    ({ auth }) => ({
      userType: auth.userType,
      businessName: auth.businessName,
      businessId: auth.businessId,
      allowBusinessToAddMembership: auth.allowBusinessToAddMembership,
      isWithDoctorNetwork: auth.isWithDoctorNetwork,
    }),
    shallowEqual
  );

  const { isMA, isAdmin } = useUserType();
  const isVSDHAdminUser = isVSDH && userType?.toLowerCase() === DataConstant.userType.Admin?.toLowerCase();

  const handleShowSelection = () => {
    if (refSwitchAccount && refSwitchAccount.current) {
      refSwitchAccount.current.handleUserTypeSelection();
    }
  };

  useEffect(() => {
    getWhiteLabelFeatureValueList();
    if (businessId && businessId > 0) {
      getFeaturePermissionValueList(businessId);
    }
  }, [businessId]);

  const getWhiteLabelFeatureValueList = () => {
    handleFetchGet(`${UrlConstant.common.getWhiteLabelFeatureValueList}`).then(
      (response) => {
        if (
          response &&
          response.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.success.toLowerCase() &&
          response.data
        ) {
          setWhitelabelFeaturesList(response.data);
        }
      }
    );
  };

  const getFeaturePermissionValueList = (businessId) => {
    const featurePermissionModel = {
      refId: parseInt(businessId, 10),
      type: DataConstant.featurePermission.refType.business,
    };
    handleFetchPost(
      `${UrlConstant.featurePermission.getFeaturePermissionValueList}`,
      JSON.stringify(featurePermissionModel)
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        setFeaturesList(response.data);
      }
    });
  };

  return (
    <>
      <SwitchUserType ref={refSwitchAccount} />
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="cm-switch-account px-5">
          <div className="cm-link" onClick={handleShowSelection}>
            {userType} -{" "}
            <FormattedMessage id="userTypeSelection.switchAccount" />
          </div>
          <div className="cm-link">{businessName}</div>
        </li>
        {/*begin::1 Level - dashboard*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.DoctorNetworkMD?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Pharmacy?.toLowerCase()) &&
          (
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-home"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.dashboard" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level - users*/}
        {((userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase()) || isVSDHAdminUser) && (
            <li
              className={`menu-item ${getMenuItemActive("/users", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/users">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.users" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Incident Report*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (<li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
              <span className="svg-icon menu-icon">
                <i className="fas fa-store"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="aside.menu.incidentReport" />
              </span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.incidentReportconfiguration" />
                      </span>
                    </span>
                  </li>

                  {/*begin::2 Level - Incident Report*/}
                  {(userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase()) && (
                      <li
                        className={`menu-item ${getMenuItemActive("/incidentReportconfiguration")}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink
                          className="menu-link ml-6"
                          to={
                            "/incidentReportconfiguration"
                          }
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.incidentReportconfiguration" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                  {
                    (
                      <>
                        <li
                          className="menu-item  menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.incidentReportList" />
                            </span>
                          </span>
                        </li>
                        {/*begin::2 Level*/}
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/incidentReportList"
                          )}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink className="menu-link ml-6" to="/incidentReportList">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.incidentReportList" />
                            </span>
                          </NavLink>
                        </li>
                      </>
                    )}
                  {/*end::2 Level*/}
                </ul>
              </ul>
            </div>
          </li>)}
        {/*end::1 Level*/}
        {/*begin::1 Level - Client Report*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (<li
            className={`menu-item ${getMenuItemActive(
              "/clientReport"
            )}`}
            aria-haspopup="true"
            onClick={resetFilter}
          >
            <NavLink className="menu-link" to="/clientReport">
              <span className="svg-icon menu-icon">
                <i className="fas fa-user-md"></i>

              </span>
              <span className="menu-text">
                <FormattedMessage id="aside.menu.clientReport" />
              </span>
            </NavLink>
          </li>)}
        {/*end::1 Level*/}

        {/* For Pharmacy User */}
        {/*begin::1 Level - Open Orders*/}
        {(userType?.toLowerCase() === DataConstant.userType.Pharmacy?.toLowerCase()) &&
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/orders/openOrder"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/orders/openOrder">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-md"></i>

                </span>
                <span className="menu-text">

                  <FormattedMessage id="aside.menu.openOrders" />
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level - Partial Orders*/}
            <li
              className={`menu-item ${getMenuItemActive(
                "/orders/partialOrder"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/orders/partialOrder">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-md"></i>

                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.partiallyOrdered" />
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level - Ordered*/}
            <li
              className={`menu-item ${getMenuItemActive(
                "/orders/ordered"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/orders/ordered">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-md"></i>

                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.ordered" />
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level - Delivered*/}
            <li
              className={`menu-item ${getMenuItemActive(
                "/orders/delivered"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/orders/delivered">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-md"></i>

                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.delivered" />
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>}
        {/*begin::1 Level - onboarding*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/onboarding",
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/onboarding">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-layer-group" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.onboarding" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/onboarding")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/onboarding">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.onboardingFlows" />
                      </span>
                    </NavLink>
                  </li>
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/adminStatus"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/adminStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingAdminStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/serviceProviderStatus"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/serviceProviderStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingServiceProviderStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/onboardingHealthcareProfessionalStatus",
                          false
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink className="menu-link menu-toggle" to="/onboardingHealthcareProfessionalStatus">
                          <span className="svg-icon menu-icon">
                            <i className="menu-bullet menu-bullet-dot" />
                          </span>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingHealthcareProfessionalStatus" />
                          </span>
                          <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu ">
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive("/hpsStandalone")}`}
                              aria-haspopup="true"
                              onClick={resetFilter}
                            >
                              <NavLink className="menu-link ml-6" to="/hpsStandalone">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">
                                  <FormattedMessage id="aside.menu.onboardingHpsStandalone" />
                                </span>
                              </NavLink>
                            </li>
                            <li
                              className={`menu-item ${getMenuItemActive("/hpsBusiness")}`}
                              aria-haspopup="true"
                              onClick={resetFilter}
                            >
                              <NavLink className="menu-link ml-6" to="/hpsBusiness">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">
                                  <FormattedMessage id="aside.menu.onboardingHpsBusiness" />
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/medicalDirectorStatus"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/medicalDirectorStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingMedicalDirectorStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/receptionistStatus"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/receptionistStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingReceptionistStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/affiliateStatus"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/affiliateStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingAffiliateStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/clientStatus "
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/clientStatus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.onboardingClientStatus" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - business*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-store"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.business" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.business" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level - business*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/business")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to={
                          userType?.toLowerCase() ===
                            DataConstant.userType.Admin.toLowerCase()
                            ? `/business/edit/${businessId}`
                            : "/business"
                        }
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.business" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                    {whitelabelFeaturesList && whitelabelFeaturesList.fC_FacilityBooking &&
                      (businessId ? featuresList?.fC_FacilityBooking : true) &&
                      (
                        <>
                          <li
                            className="menu-item  menu-item-parent"
                            aria-haspopup="true"
                          >
                            <span className="menu-link">
                              <span className="menu-text">
                                <FormattedMessage id="aside.menu.facilities" />
                              </span>
                            </span>
                          </li>
                          {/*begin::2 Level*/}
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/facility"
                            )}`}
                            aria-haspopup="true"
                            onClick={resetFilter}
                          >
                            <NavLink className="menu-link ml-6" to="/facility">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="aside.menu.facilities" />
                              </span>
                            </NavLink>
                          </li>
                        </>
                      )}
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - services*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/services",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/services">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-cog"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.services" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.services" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/service")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/service">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.service" />
                        </span>
                      </NavLink>
                    </li>
                    {isMA && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/servicecategory"
                          )}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink className="menu-link ml-6" to="/servicecategory">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.serviceCategory" />
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {/* Service Group */}
                    {isMA && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/servicegroup"
                          )}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink className="menu-link ml-6" to="/servicegroup">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.serviceGroup" />
                            </span>
                          </NavLink>
                        </li>
                      )}

                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/serviceaddonorder"
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/serviceaddonorder">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.serviceAddOnOrder" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - serviceProvider*/}
        {(isMA || isAdmin) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-toolbox"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.serviceProvider" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.serviceProvider" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/medicalassistant/sp"
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to="/medicalassistant/sp"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.serviceProvider" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - hcp*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          (userType?.toLowerCase() ===
            DataConstant.userType.Admin?.toLowerCase() && !(whitelabelFeaturesList.fC_DoctorNetwork && isWithDoctorNetwork === true)) ||
          userType?.toLowerCase() ===
          DataConstant.userType.DoctorNetworkMD?.toLowerCase()) && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/medicalassistant/hcp"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/medicalassistant/hcp">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-stethoscope"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.hcp" />
                </span>
              </NavLink>
            </li>
          )}
        {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (            
            <li
              className={`menu-item ${getMenuItemActive(
                "/preassesments"
              )}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/preassesments">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-stethoscope"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.preAssessment" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - sales*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/sales",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/sales">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-chart-line" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.sales" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/salesreport")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/salesreport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.salesReport" />
                      </span>
                    </NavLink>
                  </li>
                  { userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() ||
                    userType?.toLowerCase() ===
                    DataConstant.userType.Admin?.toLowerCase() ? 
                  <li
                    className={`menu-item ${getMenuItemActive("/psfSalesreport")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/psfSalesreport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.psfSalesReport" />
                      </span>
                    </NavLink>
                  </li> : null }
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive("/receipts")}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/receipts">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.receipts" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/tipsGratuity"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/tipsGratuity">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.tipsGratuity" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/servicesalesReport"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/servicesalesReport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.serviceSalesReport" />
                      </span>
                    </NavLink>
                  </li>
                  {whitelabelFeaturesList?.fC_DripbarEnhancement && <li
                    className={`menu-item ${getMenuItemActive(
                      "/clubReadyBookingSyncLog"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/clubReadyBookingSyncLog">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.clubReadyBookingSyncLog" />
                      </span>
                    </NavLink>
                  </li>}

                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/subscriptionReport"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/subscriptionReport">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.subscriptionReport" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - inventory*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-search-plus"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.inventory" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/inventory")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/inventory">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.inventory" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive("/inventoryUsed")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/inventoryUsed">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.inventoryUsed" />
                      </span>
                    </NavLink>
                  </li>
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/inventoryBarcodes"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/inventoryBarcodes">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.inventoryBarcodes" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/inventoryOrders"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/inventoryOrders">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.inventoryOrders" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - insurance*/}
        {whitelabelFeaturesList &&
          whitelabelFeaturesList.fC_MyndshftInsurance &&
          userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              onClick={resetFilter}
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-medkit"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.insurance" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.insurance" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/insurancesettings",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to="/insurancesettings"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.insuranceSettings" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - telemedicine*/}
        {whitelabelFeaturesList &&
          whitelabelFeaturesList.fC_ServiceConsult &&
          (userType?.toLowerCase() ===
            DataConstant.userType.MasterAdmin?.toLowerCase() ||
            userType?.toLowerCase() ===
            DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-laptop-medical"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.telemedicine" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.telemedicine" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/consultation",
                        false
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/consultation">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.consultations" />
                        </span>
                      </NavLink>
                    </li>

                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/checkin",
                        false
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/checkin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.checkins" />
                        </span>
                      </NavLink>
                    </li>

                    {userType?.toLowerCase() ===
                      DataConstant.userType.MasterAdmin?.toLowerCase() && (<li
                        className={`menu-item ${getMenuItemActive(
                          "/medicalScreeningSetting",
                          false
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/medicalScreeningSetting">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.medicalScreeningSetting" />
                          </span>
                        </NavLink>
                      </li>)}
                    {userType?.toLowerCase() ===
                      DataConstant.userType.MasterAdmin?.toLowerCase() && (
                        <>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/telemedicinesetup",
                              false
                            )}`}
                            aria-haspopup="true"
                            onClick={resetFilter}
                          >
                            <NavLink
                              className="menu-link ml-6"
                              to="/telemedicinesetup"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="aside.menu.setup" />
                              </span>
                            </NavLink>
                          </li>
                          {REACT_APP_HIDEPSF === 'Y' ? null : (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/telemedicinesetup",
                                false
                              )}`}
                              aria-haspopup="true"
                              onClick={resetFilter}
                            >
                              <NavLink
                                className="menu-link ml-6"
                                to="/telemedicinepatientSpecific"
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">
                                  <FormattedMessage id="aside.menu.patientSpecific" />
                                </span>
                              </NavLink>
                            </li>)}
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/checklist",
                              false
                            )}`}
                            aria-haspopup="true"
                            onClick={resetFilter}
                          >
                            <NavLink className="menu-link ml-6" to="/checklist">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="aside.menu.checklist" />
                              </span>
                            </NavLink>
                          </li>
                        </>
                      )}
                    {userType?.toLowerCase() ===
                      DataConstant.userType.MasterAdmin?.toLowerCase() && (<li
                        className={`menu-item ${getMenuItemActive(
                          "/mobileMedIVService",
                          false
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/mobileMedIVService">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.mobileMedIVService" />
                          </span>
                        </NavLink>
                      </li>)}
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Medical consult*/}
        {whitelabelFeaturesList &&
          whitelabelFeaturesList?.fC_MedicalConsultDosespot &&
          userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/medical-consults",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/medical-consults">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-plus-circle" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.medicalConsult" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/condition")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/condition">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.conditions" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/medicalConsultSetting"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink
                      className="menu-link ml-6"
                      to="/medicalConsultSetting"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.medicalConsultSettings" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - payout*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/payouts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/payouts">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-money-bill-alt" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.payouts" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/payout")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/payout">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.payout" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payoutAdjustments"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/payoutAdjustments">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.payoutAdjustments" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Earning*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive("/earning", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link menu-toggle" to="/earning">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-percentage"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.earning" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/commissionsBalance"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/commissionsBalance">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.commissionsBalance" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - receptionist Commission*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item ${getMenuItemActive("/receptionist", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/receptionist">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-plus"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.receptionist" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - affiliate Commission*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item ${getMenuItemActive("/affiliate", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/affiliate">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-user-plus"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.affiliate" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Location*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive("/location", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/location">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.location" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level - Reports*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/reports",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/reports">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-file" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.reports" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive("/metabase-reports/" + DataConstant.metabaseDasboardIdentifier.commissionDashboard)}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to={`/metabase-reports/${DataConstant.metabaseDasboardIdentifier.commissionDashboard}`}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.commissionDashboard" />
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/metabase-reports/" + DataConstant.metabaseDasboardIdentifier.consultationDashboard)}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to={`/metabase-reports/${DataConstant.metabaseDasboardIdentifier.consultationDashboard}`}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.consultationDashboard" />
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/metabase-reports/" + DataConstant.metabaseDasboardIdentifier.consultationDrillDown)}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to={`/metabase-reports/${DataConstant.metabaseDasboardIdentifier.consultationDrillDown}`}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.consultationDrillDown" />
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/metabase-reports/" + DataConstant.metabaseDasboardIdentifier.coverageDashboard)}`}
                    onClick={resetFilter}
                    >
                    <NavLink className="menu-link ml-6" to={`/metabase-reports/${DataConstant.metabaseDasboardIdentifier.coverageDashboard}`}>
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.coverageDashboard" />
                        </span>
                      </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/metabase-reports/" + DataConstant.metabaseDasboardIdentifier.servicesBookedDashboard)}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to={`/metabase-reports/${DataConstant.metabaseDasboardIdentifier.servicesBookedDashboard}`}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.servicesBookedDashboard" />
                      </span>
                    </NavLink>
                  </li>
           
                </ul>
              </div>
            </li>
          )}

        {/*begin::1 Level - Pharmacy*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive(isDripbar ? `${UrlConstant.PharmacyLinks.dripbar}` : `${UrlConstant.PharmacyLinks.hydreight}`, false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <a className="menu-link" href={REACT_APP_PHARMACYURL} target="_blank" rel="noopener noreferrer">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-store"></i>
                </span>
                <span className="menu-text">
                  {intl.formatMessage({ id: "aside.menu.WhiteLablePharmacy" }, {WhiteLabel: REACT_APP_WHITELABELNAME})}
                </span>
              </a>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - clientViewSetup*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/client-view-setup",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/client-view-setup">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-eye" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.clientViewSetup" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <li
                        className={`menu-item ${getMenuItemActive("/home")}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/home">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.home" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/*begin:1 Level - Event*/}
                  <li
                    className={`menu-item ${getMenuItemActive("/event")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/event">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.event" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && <li
                      className={`menu-item ${getMenuItemActive("/spotlight")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/spotlight">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.spotlight" />
                        </span>
                      </NavLink>
                    </li>}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && <li
                      className={`menu-item ${getMenuItemActive("/news")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/news">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.news" />
                        </span>
                      </NavLink>
                    </li>}
                  {/*begin::2 Level*/}
                  {userType?.toLowerCase() ===
                    DataConstant.userType.MasterAdmin?.toLowerCase() && (
                      <>
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/medicalconsent"
                          )}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink className="menu-link ml-6" to="/medicalconsent">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id={whiteLableWiseLable.medicalConsent} />
                            </span>
                          </NavLink>
                        </li>
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/clientBookingChoice"
                          )}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink
                            className="menu-link ml-6"
                            to="/clientBookingChoice"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.clientBookingChoice" />
                            </span>
                          </NavLink>
                        </li>
                      </>
                    )}
                </ul>
              </div>
            </li>
          )}
        {/*begin::1 Level - membershipPlan*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          (userType?.toLowerCase() ===
            DataConstant.userType.Admin?.toLowerCase() &&
            allowBusinessToAddMembership)) && (
            <li
              className={`menu-item ${getMenuItemActive("/membershipplan")}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/membershipplan">
                <span className="svg-icon menu-icon">
                  <i className="far fa-star"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.membershipPlan" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-bullhorn"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.marketing" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.coupon" />
                        </span>
                      </span>
                    </li>
                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/coupons")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to={"/coupons"}>
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.coupon" />
                        </span>
                      </NavLink>
                    </li>
                    {userType?.toLowerCase() ===
                      DataConstant.userType.MasterAdmin?.toLowerCase() && (
                        <li
                          className={`menu-item ${getMenuItemActive("/giftcards")}`}
                          aria-haspopup="true"
                          onClick={resetFilter}
                        >
                          <NavLink className="menu-link ml-6" to={"/giftcards"}>
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.giftCards" />
                            </span>
                          </NavLink>
                        </li>
                      )}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/lifetimerewardclub",
                        false
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to="/lifetimerewardclub"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.lifetimeRewardsClub" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.complimentaryIV" />
                        </span>
                      </span>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/complimentaryIV"
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/complimentaryIV">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.complimentaryIV" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Integration*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive("/integration", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/integration">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-plug"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.integration" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Notification*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive("/notification", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/notification">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-bell"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.notification" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - setting*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.settings" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.settings" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/countries")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/countries">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.countries" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive("/states")}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink className="menu-link ml-6" to="/states">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.states" />
                        </span>
                      </NavLink>
                    </li>
                    {userType?.toLowerCase() ===
                      DataConstant.userType.MasterAdmin?.toLowerCase() && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/subscriptions"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link ml-6" to="/subscriptions">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              <FormattedMessage id="aside.menu.subscription" />
                            </span>
                          </NavLink>
                        </li>
                      )}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/emailTemplate"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link ml-6" to="/emailTemplate">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.notificationTemplate" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Forms*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/forms",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/forms">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-book" />
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.forms" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive("/servicepack")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/servicepack">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.servicePack" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/formType/medicalForms"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink
                      className="menu-link ml-6"
                      to="/formType/medicalForms"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.medicalForms" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/formType/procedures"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/formType/procedures">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.procedures" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/formType/informationSheets"
                    )}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink
                      className="menu-link ml-6"
                      to="/formType/informationSheets"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.informationSheets" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - spWithNegativeBalance*/}
        {(userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() ||
          userType?.toLowerCase() ===
          DataConstant.userType.Admin?.toLowerCase()) && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/sp/negativebalance",
                false
              )}`}
              aria-haspopup="true"
              hidden={true}
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/sp/negativebalance">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-home"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.dashboard" />
                </span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - insurance*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.InsurancePartner?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-medkit"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.insurance" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.insurance" />
                        </span>
                      </span>
                    </li>

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/insuranceSalesReport"
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to="/insuranceSalesReport"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.insuranceSalesReport" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Payment configuration*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paymentConfigurations", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/paymentConfigurations">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-wallet"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.payments" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive("/paymentConfigurations")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/paymentConfigurations">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.paymentConfigurations" />
                      </span>
                    </NavLink>
                  </li>
                  {/*begin::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive("/businessPaymentConfiguration")}`}
                    aria-haspopup="true"
                    onClick={resetFilter}
                  >
                    <NavLink className="menu-link ml-6" to="/businessPaymentConfiguration">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="aside.menu.businessPaymentConfiguration" />
                      </span>
                    </NavLink>
                  </li>
                  {/*begin::2 Level*/}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - Training*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.MasterAdmin?.toLowerCase() && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-store"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.learningCenter" />
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.learningCenter" />
                        </span>
                      </span>
                    </li>
                    {/*begin::2 Level - Training*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/trainingdashboard"
                      )}`}
                      aria-haspopup="true"
                      onClick={resetFilter}
                    >
                      <NavLink
                        className="menu-link ml-6"
                        to={"/trainingdashboard"}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="aside.menu.trainingdashboard" />
                        </span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                    <>
                      {" "}
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.trainingdashboard" />
                          </span>
                        </span>
                      </li>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/trainingsubject"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/trainingsubject">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.trainingsubjects" />
                          </span>
                        </NavLink>
                      </li>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/trainingusers"
                        )}`}
                        aria-haspopup="true"
                        onClick={resetFilter}
                      >
                        <NavLink className="menu-link ml-6" to="/trainingusers">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            <FormattedMessage id="aside.menu.trainingusers" />
                          </span>
                        </NavLink>
                      </li>
                    </>
                    {/*end::2 Level*/}
                  </ul>
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}
        {/*begin::1 Level - DoctorNetworkMD Consultation*/}
        {userType?.toLowerCase() ===
          DataConstant.userType.DoctorNetworkMD?.toLowerCase() && (
            <li
              className={`menu-item ${getMenuItemActive("/consultation", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/consultation">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.consultations" />
                </span>
              </NavLink>
            </li>
          )}
        {((userType?.toLowerCase() !==
          DataConstant.userType.Admin?.toLowerCase())
          &&
          (userType?.toLowerCase() !==
            DataConstant.userType.Pharmacy?.toLowerCase())

        ) && (
            <li
              className={`menu-item ${getMenuItemActive("/consultationWaitingList", false)}`}
              aria-haspopup="true"
              onClick={resetFilter}
            >
              <NavLink className="menu-link" to="/consultationWaitingList">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-spinner"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="aside.menu.consultationWaitingList" />
                </span>
              </NavLink>
            </li>
          )}

        {/*end::1 Level*/}

      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
